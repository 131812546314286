.colum{
    background-color: #fff4f4;
}


.sidebarList li{
  list-style: none;
  text-decoration: none;
}

.active {
  background-color: "red"
}
ListGroup.Item :hover{
background-color: yellow !important;
}
.act{
  border: none;
  /* background-color: transparent !important; */
}
/* .act :hover{
  border: none;
  background-color: yellow !important;
} */
::ng-deep .list-group-item{
background-color: red !important;
}
::ng-deep .list-group-item-action{
background-color: seagreen !important;
}

.flex-column{
  flex-direction: column !important;
  /* overflow-x:hidden !important; */
  /* display:contents; */
}

.flexDiv{
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.userContainer {
    margin-top: .5rem;
    padding: 0;
    position: absolute;
    width: fit-content;
    top: 100%;
    background: var(--main-bg-colour);
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    right: .5%;
  }
  
  .topSection {
    padding: 1rem 1rem;
    border-bottom: 1px solid var(--secondary-accent);
  }
  
  .topSection .noImage {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem !important;
    background: var(--tertiary-accent);
    height: 3rem;
    width: 3rem;
    border: 0.063rem solid var(--text-tertiary-colour);
    border-radius: .5rem;
    color: var(--text-tertiary-colour);
  }
  
  .topSection span {
    font-size: 1.125rem !important;
    font-weight: bold !important;
    text-decoration: none !important;
    line-height: 140%;
  }
  
  .topSection p {
    color: var(--text-tertiary-colour);
    font-size: 0.875rem;
    line-height: 140%;
    font-weight: 500;
  }
  
  .userContainer ul {
    margin: 0;
    list-style-type: none;
    padding: 1rem 0 1rem 0;
  }
  
  .userContainer ul li {
    background: inherit;
    transition: all 0.2s ease-in-out;
    padding: 0.844rem 1.375rem;
    cursor: pointer;
  }
  
  .userContainer ul li:hover {
    background: var(--tertiary-accent);
  }
  
  /* .userContainer ul li:not(:first-of-type) {
    margin-top: 2rem;
  } */
  
  .userContainer ul li i {
    font-size: 1.3rem;
  }
  
  .userContainer ul li span {
    font-size: 1.125rem;
    line-height: 140%;
    font-weight: 500;
    text-decoration: none;
  }
