


:root {
  /* colours  */
  --main-blue: #2D9CDB;
  --main-yellow: #FECE2E;
  --main-bg-colour: #FFFFFF;
  --secondary-bg-colour: #000000;
  /* accent colours */
  --main-accent: #E0E0E0;
  --secondary-accent: #EDEFEF;
  --tertiary-accent: #FAFAFA;
  /* text  */
  --text-primary-colour: #000000;
  --text-secondary-colour: #FFFFFF;
  --text-tertiary-colour: #B6B6B6;
  --text-quaternary-colour: #828282;
  --text-quinary-colour: #71717A;
  --text-error: #E64848;
  /* general */
  --border-radius: 0.625rem;
  --main-page-colour: #FFFFFF;
  --error-colour: #D14141;
}
/* if we ever do darkmode */
/* @media (prefers-color-scheme: dark) {
  :root {
    --main-page-colour: #000000;
    --text-primary-colour: #ffff;
  }
} */
html, body {
  background: var(--main-page-colour);
  color: var(--text-primary-colour);
  padding: 0;
  margin: 0;
  overflow: "auto";
  font-family: ‘Gilroy’, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
}
a {
  color: inherit;
  text-decoration: none;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}
/* this disabled scroll bar globally  */
/* ::-webkit-scrollbar {
  display: none;
} */
/* class for no allowing to highlight */
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* class for no auto fill input effect  */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #FFFFFF inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset !important;
}
/* removes weird appearnces on different platforms - eg: IOS has weird input top shadow */
input, textarea {
  /* padding: 0; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input:focus, textarea:focus {
  outline: none;
}
/* adding onto bootstrap container class to ensure the height is always the height of device screen to make it look nice on mobile and desktop */
.fullHeight {
  min-height: var(--vh);
  display: flex;
  flex-direction: column;
}
