.multiselect > .multiSelectContainer{
    width: 450px !important;
    padding: 0px;
    background-color: var(--tertiary-accent);
}
.optionListContainer{
    background-color: var(--tertiary-accent);
}

.search-wrapper{
    height: 3.375rem;
    border-radius: 8px;
    border: 0.063rem solid var(--main-accent);
    padding: 9px;
}


.tableLogo{
    height: 45px;
}

.select1{
    /* display: flex;
    justify-content: center; */
    height: 3.375rem;
    width: 450px;
    border-radius: 8px;
    border:0.063rem solid var(--main-accent);
    background-color: var(--tertiary-accent);
}

.savebutton{
    background-color: rgb(250, 194, 53);
    border-radius: 8px;
    font-size: 17px;
    height: 3.375rem;
    width: 450px;
    border:none;
}

.text{
    padding-left: 3.4rem;
    color: gray;
    
}



