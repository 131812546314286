.border{
    border-bottom: 2px black;
    height: 100px;
    
}

.react-tel-input {
	font-size: 0.9rem !important;
}

.react-tel-input .form-control {
	/* margin-top: 0 !important;
	margin-bottom: 0 !important;
	position: relative;
	letter-spacing: .01rem;
    margin-top: 20px;
	border-radius: 10px !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important; */
	width: 100% !important;
	font-size: 1.5rem !important;
    height: 4.5rem !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
}
  /* .fas{
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(-45deg); 
  } */

  
  
  .Input_contentContainer__2qM_i {
    border-radius: 0.625rem 0 0 0.625rem;
    /* border: 0.063rem solid var(--main-accent); */
    background: var(--main-bg-colour);
    padding: 0.875rem 0 0.875rem 1rem;
    width: 100%;
}

.Button_signupButton__pGlka {
    min-width: 3.75rem;
    max-width: 3rem;
    background-color: var(--main-yellow) !important;
    /* display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out; */
}

.Button_button__sL3tD {
    
    background-color: var(--main-bg-colour);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /* color: var(--text-primary-colour);
    width: 100%;
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out; */
    outline: none;
    border: none;
}

.font{
    font-weight: 380;
    line-height: 150%;
    margin: 0;
    box-sizing: border-box;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center!important;
   font-size:23px;
    
    
}

.App {
    font-family: sans-serif;
    text-align: center;
    background-color: #f3f3f3;
    padding: 20px;
  }

  .button{
      background-color: rgb(250, 194, 53);
      border-radius: 10px;
      border: none;
      height: 50px;
  }
