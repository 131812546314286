.addbtn{
    border-radius: 5px;
    border: none;
    background-color: rgb(250, 194, 53);
    height: 35px;
    width: 80px;
    font-size: 19px;
    float: right;
    color: black;
}

/* .multiSelectContainer{
    width: 59% !important;
} */
.select{
    /* display: flex;
    justify-content: center; */
    height: 40px;
    width: 350px;
    border-radius: 7px;
    border: 1px solid black;
   
    
}