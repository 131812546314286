.dropdown-toggle:after {
    display: none;
}

.mainDiv{
    height: 100%;
    padding-bottom: 32px;
    overflow-x: scroll;
    overflow-y: scroll;
}
/* .box{
    height: 140px;
    border: none !important;
    border-radius: 10px;

} */
.act{
    background-color: rgb(191, 213, 231);
}

.button1{
    background-color:white;
    border-radius: 8px;
    font-size: 17px;
    border:2px solid black;
    height: 50px;
}
.button2{
    background-color: rgb(250, 194, 53);
    border-radius: 8px;
    font-size: 17px;
    height: 50px;
    border:none;
}

.tableDiv .MuiTableCell-head{ 
    white-space: nowrap;
}
