

.paperStyle{
    width: 100%;
    height: 100%;
    border-radius: 8px !important;
    text-align: center;
    padding-top: 8px;
    padding: 8px; 
    background-color: var(--tertiary-accent) !important;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%) !important;
    
}
.paper{
    border-radius: 8px !important;
    background-color: var(--tertiary-accent) !important;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%) !important;   
}
.datepicker{
    border-radius: 5px !important;
    border:1px solid black;
}
